<template>
  <v-card class="orgcard mx-auto" elevation="0" outlined @click="handlePickOrganization">
    <div class="orgcard__img-wrapper">
      <img
        v-if="
          (org.logo && org.logo.path) ||
            (org.landingPageSettings &&
              org.landingPageSettings.logo &&
              org.landingPageSettings.logo.path)
        "
        :src="getOrgLogoPath((org.logo && org.logo.path) || org.landingPageSettings.logo.path)"
        class="orgcard__img"
      />
      <img v-else src="@/assets/img/org-avatar-placeholder.svg" class="orgcard__img" />
    </div>
    <div class="orgcard__panel" height="71.5%">
      <v-card-title class="orgcard__title pa-0 ma-0">
        {{ org.name }}
      </v-card-title>
      <v-card-subtitle class="orgcard__title pa-0 ma-0">
        {{ computedDate }}
        <v-icon class="orgcard__arrow">mdi-chevron-right</v-icon>
      </v-card-subtitle>
    </div>
  </v-card>
</template>

<script>
import moment from 'moment';
import { DISPLAY_DATE_FORMAT } from '@/utils/constants';
import { getRemoteFilePath } from '@/utils/helpers/string';

export default {
  name: 'OrgCard',
  props: {
    org: {
      type: Object,
    },
  },
  computed: {
    computedDate() {
      if (this.org && this.org.createdAt) {
        const date = this.org.createdAt.substr(0, 10);
        return moment(date).format(DISPLAY_DATE_FORMAT);
      }
      return '';
    },
  },
  methods: {
    getOrgLogoPath(path) {
      return getRemoteFilePath(path);
    },
    handlePickOrganization() {
      this.$emit('showOrg', this.org.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.orgcard {
  width: 100%;
  border-color: #eceff6;
  border-radius: 8px;
  cursor: pointer;
}

.orgcard__panel {
  border-top: 1px solid #eceff6;
  padding: 8px 13px 10px;
}

.orgcard__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #303030;
  position: relative;
  max-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.orgcard__img-wrapper {
  height: 71%;
}

.orgcard__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 20px;
}

.orgcard__subtitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.666666px;
  color: $text-light;
}
.orgcard__arrow {
  position: absolute;
  right: 0px;
  bottom: 0px;
}
</style>
