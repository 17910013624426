import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0eed0df8&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=0eed0df8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0eed0df8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import VBtn from '@/components/HOC/VBtn'
import VCheckbox from '@/components/HOC/VCheckbox'
import { VChip } from 'vuetify/lib/components/VChip';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import VSelect from '@/components/HOC/VSelect'
import VTextField from '@/components/HOC/VTextField'
installComponents(component, {VBtn,VCheckbox,VChip,VColorPicker,VFlex,VForm,VLayout,VSelect,VTextField})
