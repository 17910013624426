<template>
  <PhDialog
    :value="showOrgCreate"
    fullscreen
    :shadowContentScroll="true"
    @input="closeOrgCreateModal"
    closeBtnText="ADD"
    hideFooter
  >
    <h1 class="orgcreate-header" slot="header" v-if="type === 'sport'">
      {{ showOrgType }} Sport Organization
    </h1>
    <h1 class="orgcreate-header" slot="header" v-else>{{ showOrgType }} Broker Organization</h1>
    <div slot="content" class="org-create__content">
      <OrgForm
        :orgForm="orgForm"
        :type="type"
        ref="orgFormComponent"
        :loading="loading"
        @orgFormUpdate="handleOrgFormUpdate"
        @orgFormAddContact="handleAddBrokerContact"
        @orgFormRemoveContact="handleRemoveBrokerContact"
        @generateLink="handleGenerateLink"
        @flushLink="handleFlushLink"
        @landingPageSettingsUpdate="landingPageSettingsUpdate"
        @orgFormLogoUpdate="handleOrgLogoFormUpdate"
        @submit="handleSubmit"
      ></OrgForm>
    </div>
  </PhDialog>
</template>

<script>
import PhDialog from '@/components/modals/PhDialog';
import OrgForm from '@/components/forms/OrgForm';

export default {
  name: 'OrgCreate',
  props: {
    showOrgCreate: Boolean,
    loading: Boolean,
    type: {
      type: String,
      required: true,
    },
    orgForm: {
      type: Object,
      required: true,
    },
    showOrgType: {
      type: String,
      default: 'create',
    },
  },
  components: {
    PhDialog,
    OrgForm,
  },
  watch: {
    showOrgCreate: {
      handler(newVal) {
        if (!!newVal && this.showOrgType === 'edit') {
          this.$emit('prepareContentToEdit');
        } else if (this.$refs && this.$refs.orgFormComponent) {
          this.$emit('flushForm');
          if (
            this.$refs.orgFormComponent.$refs &&
            this.$refs.orgFormComponent.$refs.avatarUploader
          ) {
            this.$refs.orgFormComponent.$refs.avatarUploader.removeFile();
          }
          this.$refs.orgFormComponent.reset();
        }
      },
    },
  },
  data: () => ({}),
  methods: {
    closeOrgCreateModal() {
      this.$emit('closeOrgCreateDialog');
    },
    handleAddBrokerContact() {
      this.$emit('orgFormAddContact');
    },
    handleRemoveBrokerContact() {
      this.$emit('orgFormRemoveContact');
    },
    handleOrgFormUpdate(data) {
      this.$emit('orgFormUpdate', data);
    },
    landingPageSettingsUpdate(data) {
      this.$emit('landingPageSettingsUpdate', data);
    },
    handleOrgLogoFormUpdate(data) {
      this.$emit('orgFormLogoUpdate', data);
    },
    handleSubmit() {
      this.$emit('submit');
    },
    handleGenerateLink() {
      this.$emit('generateLink');
    },
    handleFlushLink() {
      this.$emit('flushLink');
    },
  },
};
</script>

<style lang="scss">
.org-create__form-title {
  text-align: left;
}
.org-create__content {
  height: 100%;
  overflow-y: auto;
}
.orgcreate-header {
  text-transform: capitalize;
}
</style>
