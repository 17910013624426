<template>
  <div class="view-placeholder">
    <div class="view-placeholder__wrapper">
      <div class="view-placeholder__content">
        <img class="view-placeholder__image" :src="getProperImg" alt="placeholder" />
        <p class="view-placeholder__text">No organizations yet</p>
        <p class="view-placeholder__centred">
          <slot></slot>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
const EMPTY_ORGS = require('@/assets/img/empty-orgs.svg');

export default {
  name: 'ViewPlaceholder',
  props: {
    type: String,
  },
  computed: {
    getProperImg() {
      if (this.type === 'orgs') {
        return EMPTY_ORGS;
      }
      return EMPTY_ORGS;
    },
  },
};
</script>

<style lang="scss" scoped>
.view-placeholder__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 100px;
}

.view-placeholder__image {
  display: block;
  max-width: 70px;
  margin: 0 auto;
}

.view-placeholder__text {
  margin: 0 0 24px;
  font-size: 28px;
  line-height: 58px;
  text-align: center;
  letter-spacing: 1.5px;
  color: $text-semi-dark;
}

.view-placeholder__centred {
  margin: 0;
  text-align: center;
}
</style>
