<template>
  <v-form @submit.prevent="submit" ref="form" class="org-form" id="form">
    <v-layout row wrap class="org-form__content">
      <v-flex xs12 md12 px-2>
        <v-chip class="mb-2" label>Logo:</v-chip>
        <AvatarUploader
          v-if="type === 'sport'"
          :item="orgForm.landingPageSettings.logo"
          ref="avatarUploader"
          @upload="handleLandingPageSettingsInput('logo', arguments[0])"
          class="org-form__avatar"
        />
        <AvatarUploader
          v-else
          :item="orgForm.logoId"
          ref="avatarUploader"
          @upload="handleOrgAvatarInput('logoId', arguments[0])"
          class="org-form__avatar"
        />
      </v-flex>
      <v-flex xs12 md12 px-2 v-if="type === 'sport'">
        <v-select
          @input="handleOrgInput('brokerId', arguments[0])"
          :items="computedBrokerOrgs"
          :value="orgForm.brokerId"
          input-text="text"
          input-value="value"
          :rules="['required']"
          placeholder="Broker ID"
        />
      </v-flex>
      <v-flex xs12 md12 px-2>
        <v-text-field
          autocomplete="new-password"
          @input="handleOrgNameInput('name', arguments[0])"
          :value="orgForm.name"
          :rules="['required']"
          label="Org Name"
        />
      </v-flex>
      <v-flex xs12 md12 px-2>
        <v-text-field
          autocomplete="new-password"
          @input="handleOrgInput('streetAddress', arguments[0])"
          :value="orgForm.address.streetAddress"
          :rules="['required']"
          label="Address"
        />
      </v-flex>
      <v-flex xs12 md12 px-2>
        <v-text-field
          autocomplete="new-password"
          @input="handleOrgInput('city', arguments[0])"
          :value="orgForm.address.city"
          :rules="['required']"
          label="City"
        />
      </v-flex>
      <v-flex xs12 md12 px-2>
        <v-select
          @input="
            val => {
              orgForm.address.state && handleOrgInput('state', null);
              handleOrgInput('country', val);
            }
          "
          :items="countriesOptions"
          :value="orgForm.address.country"
          :rules="['required']"
          :placeholder="$t('fields.country')"
        />
      </v-flex>
      <v-flex xs12 md12 px-2>
        <v-select
          :disabled="!orgForm.address.country"
          :items="statesOptions[orgForm.address.country]"
          @input="handleOrgInput('state', arguments[0])"
          :placeholder="$t('fields.state')"
          :value="orgForm.address.state"
          :rules="['required']"
        />
      </v-flex>
      <v-flex xs12 md12 px-2>
        <v-text-field
          autocomplete="new-password"
          @input="handleOrgInput('zip', arguments[0])"
          :value="orgForm.address.zip"
          :rules="['required']"
          label="ZIP"
        />
      </v-flex>
      <template v-if="type === 'broker'">
        <v-flex xs12 md12 px-2>
          <h3 class="mb-2">Contact At Insured:</h3>
        </v-flex>
        <v-flex v-if="!orgForm.contact" xs12 md12 px-2>
          <div class="org-info__btns">
            <v-btn @click="handleAddContactClick" outlined color="primary" rounded>
              Add Contact
            </v-btn>
          </div>
        </v-flex>
        <template v-else>
          <v-flex xs12 md12 px-2>
            <div class="org-info__btns">
              <v-btn @click="handleRemoveContactClick" outlined color="primary" rounded>
                Remove Contact
              </v-btn>
            </div>
          </v-flex>
          <v-flex xs12 md12 px-2>
            <v-text-field
              autocomplete="new-password"
              @input="handleBrokerOrgContactInput('firstName', arguments[0])"
              :value="orgForm.contact.firstName"
              :rules="['required', 'crawfordFirstName']"
              label="First Name"
            />
          </v-flex>
          <v-flex xs12 md12 px-2>
            <v-text-field
              autocomplete="new-password"
              @input="handleBrokerOrgContactInput('lastName', arguments[0])"
              :value="orgForm.contact.lastName"
              :rules="['required', 'crawfordLastName']"
              label="Last Name"
            />
          </v-flex>
          <v-flex xs12 md12 px-2>
            <v-text-field
              autocomplete="new-password"
              @input="handleBrokerOrgContactInput('email', arguments[0] || null)"
              type="email"
              :value="orgForm.contact.email"
              :rules="['email', 'crawfordEmail']"
              label="Email"
            />
          </v-flex>
          <v-flex xs12 md12 px-2>
            <v-text-field
              autocomplete="new-password"
              @input="
                handleBrokerOrgContactInput('workPhone', sanitizePhoneNumberInput(arguments[0]))
              "
              @keydown="preventNondigits(arguments[0])"
              :value="formatPhoneNumber(orgForm.contact.workPhone)"
              :rules="['crawfordWorkPhone']"
              type="tel"
              label="Phone Number"
            >
              <template v-slot:prepend-inner>+1</template>
            </v-text-field>
          </v-flex>
        </template>
      </template>
      <v-flex xs12 md12 px-2 v-if="type === 'sport'">
        <v-checkbox
          @change="handleOrgPrivateInput('private', arguments[0] || false)"
          :value="orgForm.private"
          :input-value="orgForm.private"
          class="mt-1"
          label="Make private"
        >
        </v-checkbox>
      </v-flex>
      <v-flex xs12 md12 px-2 v-if="type === 'sport'">
        <!-- <v-checkbox
          @change="handleOrgInput('olympic', arguments[0] || false)"
          :value="orgForm.olympic"
          :input-value="orgForm.olympic"
          class="mt-1"
          label="National Team"
        >
        </v-checkbox> -->
        <v-select
          @input="handleOrgInput('form', arguments[0])"
          :items="orgFormOptions"
          :value="orgForm.form"
          :rules="['required']"
          placeholder="Form"
          label="Form"
          :solo="false"
        />
      </v-flex>
      <v-flex xs12 md12 px-2 v-if="showIsInjuryOnly">
        <v-checkbox
          @change="handleOrgInput('formIsInjuryOnly', arguments[0] || false)"
          :value="orgForm.formIsInjuryOnly"
          :input-value="orgForm.formIsInjuryOnly"
          class="mt-1"
          label="Injury Only"
        >
        </v-checkbox>
      </v-flex>
      <v-flex xs12 md12 px-2 v-if="type === 'sport'">
        <v-text-field
          @input="handleOrgInput('url', arguments[0])"
          :value="orgForm.url"
          :disabled="orgForm.private"
          :rules="['claimCreateSlug']"
          label="URL"
        />
      </v-flex>
      <template v-if="type === 'sport'">
        <h3 class="mb-2">Landing page settings:</h3>
        <v-flex xs12 md12 px-2>
          <v-chip class="mb-2" label>Plain text logo:</v-chip>
          <AvatarUploader
            square
            :item="orgForm.landingPageSettings.plainTextLogoBg"
            @upload="handleLandingPageSettingsInput('plainTextLogoBg', arguments[0])"
            class="org-form__avatar"
          />
        </v-flex>
        <v-flex xs12 md12 px-2>
          <v-chip class="mb-2" label>Background image:</v-chip>
          <AvatarUploader
            square
            :item="orgForm.landingPageSettings.bgImage"
            @upload="handleLandingPageSettingsInput('bgImage', arguments[0])"
            class="org-form__avatar"
          />
        </v-flex>

        <v-flex xs12 md12 px-2>
          <v-chip class="mb-2" label>Primary color:</v-chip>
          <v-color-picker
            @input="handleLandingPageSettingsInput('primaryColor', arguments[0])"
            :value="orgForm.landingPageSettings.primaryColor"
            flat
            mode="hexa"
            hide-mode-switch
            class="ma-2"
            hide-canvas
          ></v-color-picker>
        </v-flex>

        <v-flex xs12 md12 px-2>
          <v-chip class="mb-2" label>Secondary color:</v-chip>
          <v-color-picker
            @input="handleLandingPageSettingsInput('secondaryColor', arguments[0])"
            :value="orgForm.landingPageSettings.secondaryColor"
            flat
            mode="hexa"
            hide-mode-switch
            class="ma-2"
            hide-canvas
          ></v-color-picker>
        </v-flex>

        <v-flex xs12 md12 px-2>
          <v-chip class="mb-2" label>Background color:</v-chip>
          <v-color-picker
            @input="handleLandingPageSettingsInput('bgColor', arguments[0])"
            :value="orgForm.landingPageSettings.bgColor"
            flat
            mode="hexa"
            hide-mode-switch
            class="ma-2"
            hide-canvas
          ></v-color-picker>
        </v-flex>
        <v-flex xs12 md12 px-2>
          <v-chip class="mb-2" label>Hero text color:</v-chip>
          <v-color-picker
            @input="handleLandingPageSettingsInput('heroTextColor', arguments[0])"
            :value="orgForm.landingPageSettings.heroTextColor"
            flat
            mode="hexa"
            hide-mode-switch
            class="ma-2"
            hide-canvas
          ></v-color-picker>
        </v-flex>

        <v-flex xs12 md12 px-2>
          <v-chip class="mb-2" label>Steps background color:</v-chip>
          <v-color-picker
            @input="handleLandingPageSettingsInput('stepsBgColor', arguments[0])"
            :value="orgForm.landingPageSettings.stepsBgColor"
            flat
            mode="hexa"
            hide-mode-switch
            class="ma-2"
            hide-canvas
          ></v-color-picker>
        </v-flex>

        <v-flex xs12 md12 px-2>
          <v-text-field
            @input="handleLandingPageSettingsInput('videoSrc', arguments[0])"
            :value="orgForm.landingPageSettings.videoSrc"
            label="Video url"
          />
        </v-flex>
        <v-flex xs12 md12 px-2>
          <v-text-field
            @input="handleSocialInput('instagram', arguments[0])"
            :value="orgForm.landingPageSettings.social.instagram"
            label="Instagram url"
          />
        </v-flex>
        <v-flex xs12 md12 px-2>
          <v-text-field
            @input="handleSocialInput('youtube', arguments[0])"
            :value="orgForm.landingPageSettings.social.youtube"
            label="Youtube url"
          />
        </v-flex>
        <v-flex xs12 md12 px-2>
          <v-text-field
            @input="handleSocialInput('facebook', arguments[0])"
            :value="orgForm.landingPageSettings.social.facebook"
            label="Facebook url"
          />
        </v-flex>
        <v-flex xs12 md12 px-2>
          <v-text-field
            @input="handleSocialInput('twitter', arguments[0])"
            :value="orgForm.landingPageSettings.social.twitter"
            label="Twitter url"
          />
        </v-flex>
        <v-flex xs12 md12 px-2>
          <v-text-field
            @input="handleSocialInput('vimeo', arguments[0])"
            :value="orgForm.landingPageSettings.social.vimeo"
            label="Vimeo url"
          />
        </v-flex>
      </template>
      <p class="org-form__submit">
        <v-btn @click="submit" :loading="loading">
          Submit
        </v-btn>
      </p>
    </v-layout>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import { validateForm, withCountryStateOptions, phoneNumberMethods } from '@/mixins';
import { SPORTS_UNION_TYPES } from '@/utils/constants';

const AvatarUploader = () => import('@/components/AvatarUploader');

export default {
  name: 'OrgForm',
  mixins: [validateForm, withCountryStateOptions, phoneNumberMethods],
  components: {
    AvatarUploader,
  },
  props: {
    orgForm: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    loading: Boolean,
  },
  computed: {
    ...mapGetters('brokerOrganizations', ['brokerOrganizations']),
    orgFormOptions() {
      return Object.values(SPORTS_UNION_TYPES);
    },
    showIsInjuryOnly() {
      return this.orgForm.form === SPORTS_UNION_TYPES.CRAWFORD;
    },
    computedBrokerOrgs() {
      if (this.brokerOrganizations.length) {
        return this.brokerOrganizations.map(org => {
          return {
            text: org.name,
            value: org.id,
          };
        });
      }
      return [];
    },
  },
  methods: {
    handleLandingPageSettingsInput(field, value) {
      this.$emit('landingPageSettingsUpdate', {
        field,
        value,
      });
    },
    handleSocialInput(field, value) {
      this.handleLandingPageSettingsInput('social', {
        ...this.orgForm.landingPageSettings.social,
        [field]: value,
      });
    },
    handleOrgAvatarInput(field, value) {
      this.$emit('orgFormLogoUpdate', { field, value });
    },
    handleOrgInput(field, value) {
      this.$emit('orgFormUpdate', {
        field,
        value,
      });
    },
    handleOrgPrivateInput(field, value) {
      this.$emit('orgFormUpdate', {
        field,
        value,
      });
      if (value) {
        this.$emit('generateLink');
      } else {
        this.$emit('flushLink');
      }
    },
    handleOrgNameInput(field, value) {
      this.$emit('orgFormUpdate', {
        field,
        value,
      });
      if (this.type === 'sport' && !this.orgForm.private) {
        this.$emit('orgFormUpdate', {
          field: 'url',
          value: value && value.replace(/ /g, '-'),
        });
      }
    },
    handleAddContactClick() {
      this.$emit('orgFormAddContact');
    },
    handleRemoveContactClick() {
      this.$emit('orgFormRemoveContact');
    },
    handleBrokerOrgContactInput(field, value) {
      this.$emit('orgFormUpdate', {
        field: 'contact',
        value: [field, value],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.org-form {
  text-align: left;
}

.org-form__title {
  text-align: left;
}
.org-form__content {
  max-width: 380px;
  margin: 0 auto;
  padding: 8px 0;
}
.org-form__submit {
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: right;
}

.org-form__avatar {
  margin-bottom: 20px;
}

.org-info__btns {
  margin-bottom: 20px;
}
</style>
