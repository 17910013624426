import { mapGetters } from 'vuex';

const { uuid } = require('uuidv4');

export const EMPTY_BROKER_FORM = {
  name: '',
  address: {
    country: '',
    city: '',
    state: '',
    zip: '',
    streetAddress: '',
    additionalAddress: '',
  },
  logoId: null,
  contact: null,
};

export const EMPTY_ORG_FORM = {
  name: '',
  brokerId: '',
  type: 'SCHOOL_DISTRICT_ORGANIZATION',
  address: {
    country: '',
    city: '',
    state: '',
    zip: '',
    streetAddress: '',
    additionalAddress: '',
  },
  url: '',
  private: false,
  olympic: false,
  landingPageSettings: {
    logo: null,
    plainTextLogoBg: null,
    bgImage: null,
    primaryColor: null,
    secondaryColor: null,
    stepsBgColor: null,
    bgColor: null,
    heroTextColor: null,
    videoSrc: '',
    social: {
      vimeo: '',
      instagram: '',
      youtube: '',
      facebook: '',
      twitter: '',
    },
  },
  form: 'DEFAULT',
  formIsInjuryOnly: null,
};

export default {
  data() {
    return {
      orgForm: { ...EMPTY_ORG_FORM },
      brokerOrgForm: { ...EMPTY_BROKER_FORM },
    };
  },
  computed: {
    ...mapGetters('app', ['infoSidebarData', 'sidebarContent']),
  },
  methods: {
    handleGenerateLink() {
      const url = uuid();
      this.orgForm.url = url;
    },
    handleFlushLink() {
      this.orgForm.url = '';
    },
    handleUpdateOrgForm(data) {
      if (
        data.field === 'name' ||
        data.field === 'url' ||
        data.field === 'private' ||
        data.field === 'brokerId' ||
        data.field === 'olympic' ||
        data.field === 'form' ||
        data.field === 'formIsInjuryOnly'
      ) {
        this.orgForm[data.field] = data.value;
      } else {
        this.orgForm.address[data.field] = data.value;
      }
    },
    handleAddBrokerContact() {
      this.brokerOrgForm.contact = this.brokerOrgForm.contact || {
        firstName: null,
        lastName: null,
        email: null,
        workPhone: null,
      };
    },
    handleRemoveBrokerContact() {
      this.brokerOrgForm.contact = null;
    },
    handleUpdateBrokerOrgForm(data) {
      if (data.field === 'name') {
        this.brokerOrgForm[data.field] = data.value;
      } else if (data.field === 'contact') {
        if (!this.brokerOrgForm.contact) {
          this.brokerOrgForm.contact = {};
        }
        const [field, value] = data.value;
        this.brokerOrgForm.contact[field] = value;
      } else {
        this.brokerOrgForm.address[data.field] = data.value;
      }
    },
    async handleLandingPageSettingsInput({ field, value }) {
      if (value instanceof File) {
        const upload = await this.$store.dispatch('files/uploadFile', value);
        if (
          this.infoSidebarData.content === 'brokerOrganization' ||
          this.updateOrgDialog.content === 'brokerOrganization'
        ) {
          Object.assign(this.brokerOrgForm.landingPageSettings, {
            [field]: {
              id: upload.id,
              path: upload.path,
            },
          });
        }
        if (
          this.infoSidebarData.content === 'organization' ||
          this.updateOrgDialog.content === 'organization'
        ) {
          Object.assign(this.orgForm.landingPageSettings, {
            [field]: {
              id: upload.id,
              path: upload.path,
            },
          });
        }
      } else {
        Object.assign(this.orgForm.landingPageSettings, { [field]: value });
      }
    },
    async handleUserOrgFileInput({ field, value }) {
      if (field === 'logoId') {
        try {
          this.orgLoading = true;
          const upload = await this.$store.dispatch('files/uploadFile', value);
          if (
            this.infoSidebarData.content === 'brokerOrganization' ||
            this.updateOrgDialog.content === 'brokerOrganization'
          ) {
            Object.assign(this.brokerOrgForm, {
              logoId: {
                id: upload.id,
                path: upload.path,
              },
            });
          }
          if (
            this.infoSidebarData.content === 'organization' ||
            this.updateOrgDialog.content === 'organization'
          ) {
            Object.assign(this.orgForm, {
              logoId: {
                id: upload.id,
                path: upload.path,
              },
            });
          }
          this.orgLoading = false;
          return !!upload;
        } catch (error) {
          this.orgLoading = false;
          console.error(error);
          this.$dialog.error({ error });
          return false;
        }
      }
      return true;
    },
    handlePrepareOrgToEdit() {
      if (
        this.sidebarContent &&
        this.sidebarContent instanceof Object &&
        Object.values(this.sidebarContent).length
      ) {
        if (this.infoSidebarData.content === 'brokerOrganization') {
          if (this.sidebarContent.logo && this.sidebarContent.logo.id) {
            this.brokerOrgForm.logoId = {
              id: this.sidebarContent.logo.id,
              path: this.sidebarContent.logo.path,
            };
          } else {
            this.brokerOrgForm.logoId = null;
          }
          this.brokerOrgForm.name = this.sidebarContent.name;
          this.brokerOrgForm.address.country = this.sidebarContent.address.country;
          this.brokerOrgForm.address.city = this.sidebarContent.address.city;
          this.brokerOrgForm.address.zip = this.sidebarContent.address.zip;
          this.brokerOrgForm.address.state = this.sidebarContent.address.state;
          this.brokerOrgForm.address.streetAddress = this.sidebarContent.address.streetAddress;
          if (this.sidebarContent.contact) {
            this.brokerOrgForm.contact = {
              firstName: this.sidebarContent.contact.firstName,
              lastName: this.sidebarContent.contact.lastName,
              email: this.sidebarContent.contact.email,
              workPhone: this.sidebarContent.contact.workPhone,
            };
          } else {
            this.brokerOrgForm.contact = null;
          }
        }
        if (this.infoSidebarData.content === 'organization') {
          this.orgForm.name = this.sidebarContent.name;
          this.orgForm.brokerId = this.sidebarContent.broker.id;
          this.orgForm.address.country = this.sidebarContent.address.country;
          this.orgForm.address.city = this.sidebarContent.address.city;
          this.orgForm.address.zip = this.sidebarContent.address.zip;
          this.orgForm.address.state = this.sidebarContent.address.state;
          this.orgForm.address.streetAddress = this.sidebarContent.address.streetAddress;
          this.orgForm.landingPageSettings = {
            ...this.sidebarContent.landingPageSettings,
            ...(() => {
              return this.sidebarContent.landingPageSettings &&
                this.sidebarContent.landingPageSettings.social
                ? {
                    social: this.sidebarContent.landingPageSettings.social.reduce(
                      (acc, { icon, src }) => ({
                        ...acc,
                        [icon]: src,
                      }),
                      {}
                    ),
                  }
                : {
                    social: {
                      vimeo: '',
                      instagram: '',
                      youtube: '',
                      facebook: '',
                      twitter: '',
                    },
                  };
            })(),
          };
          delete this.orgForm.landingPageSettings.__typename;
          this.orgForm.url = this.sidebarContent.url;
          this.orgForm.private = this.sidebarContent.private;
          this.orgForm.olympic = this.sidebarContent.olympic;
          this.orgForm.form = this.sidebarContent.form;
          this.orgForm.formIsInjuryOnly = this.sidebarContent.formIsInjuryOnly;
        }
      }
    },
    handleFlushForm() {
      this.orgForm = Object.assign({}, { ...EMPTY_ORG_FORM });
      this.brokerOrgForm = Object.assign({}, { ...EMPTY_BROKER_FORM });
    },
  },
};
